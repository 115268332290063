<template>
	<div>
		<form id="payment-inputs" class="light-inputs" @submit.prevent="next">
			<template v-if="settings.RequirePaymentInfo">
				<h3>Betalingsmethode</h3>
				<div id="form-grid">
					<input placeholder="Rekeninghouder" class="input-grid-full" v-model="input.PaymentInfo.AccountHolder"
						:disabled="!input.PaymentInfo.CanEdit" :required="input.PaymentInfo.CanEdit" />
					<input placeholder="IBAN" class="input-grid-full" v-model="input.PaymentInfo.Iban"
						:disabled="!input.PaymentInfo.CanEdit" :required="input.PaymentInfo.CanEdit" />
				</div>
			</template>
			<h3 class="h3-divider">Voorwaarden</h3>
			<label class="container">
				Ik machtig {{companyName}} en/of een door haar aangewezen partijen tot het doen van aangifte diefstal en/ of verduistering bij de politie.
				<input type="checkbox" v-model="input.AcceptedPolice" />
				<span class="checkmark"></span>
			</label>
			<label class="container">
				Ik ga akkoord met de <a :href="settings.TermsAndConditionsLink" target="_blank">Algemene Voorwaarden</a> van {{companyName}}.
				<input type="checkbox" v-model="input.AcceptedTermsAndConditions" />
				<span class="checkmark"></span>
			</label>
			<label class="container" v-if="settings.ServiceCosts > 0">
				Ik ga akkoord met de eenmalige activatie- en certificaatkosten van &euro;{{settings.ServiceCosts}}.
				<input type="checkbox" v-model="input.AcceptedServiceCost" />
				<span class="checkmark"></span>
			</label>
			<div id="payment-footer">
				<button @click="back">Terug</button>
				<button class="red-button">Controleren</button>
			</div>
		</form>
		<div id="payment-checking">
			<h3>Overzicht</h3>
			<table>
				<tr>
					<td>Abonnement</td>
					<td>{{input.SubscriptionInfo.Name}}</td>
				</tr>
				<tr v-if="settings.ServiceCosts">
					<td>Servicekosten (eenmalig)</td>
					<td>&euro;{{settings.ServiceCosts}}</td>
				</tr>
				<tr>
					<td>Abonnement (jaarlijks)</td>
					<td>&euro;{{input.SubscriptionInfo.Price}}</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
import IBAN from "iban";

import { mapState } from "vuex";

export default {
	data() {
		return {
			settings: {
				ServiceCosts: "",
				TermsAndConditionsLink: "",
				Plan: "",
				PlanPrice: "",
				RequirePaymentInfo: true,
			},
			input: {
				PaymentInfo: {
					CanEdit: false,
					AccountHolder: null,
					Iban: null,
          CountryCode: "NL",
          AttentionOf: null,
				},
				SubscriptionInfo: {
					Name: null,
					Price: null
				}
			},
			companyName: process.env.VUE_APP_TITLE,
		};
	},
	created() {
		this.$emit("changeStep", 3);
		this.$api
			.get(`Payment/Load/${process.env.VUE_APP_TITLE}`)
			.then((response) => {
				this.settings = response.data;

				// Demo mode: don't load already filled out activation data because we don't have an actual activation.
				if (this.$cookies.get("activation-code") === "demo"){
					this.input.SubscriptionInfo.Name = "Demo Abonnement";
					this.input.SubscriptionInfo.Price = 0;
					this.input.PaymentInfo.CanEdit = true;
					return;
				}

				this.$api.get(`Payment/Data`).then((response) => {
					this.input = response.data;
				});
		});

		

		this.$api.get(`Data/${process.env.VUE_APP_TITLE}`).then((response) => {
			if (response.data.Name) {
				this.companyName = response.data.Name;
			}

		
		});
	},
	methods: {
		back() {
			this.$router.push("/plan");
		},
		next(e) {
			this.$store.commit("clearValidationErrors");
			let validationErrors = [];

			if (this.settings.RequirePaymentInfo && this.input.PaymentInfo.CanEdit){
				if (!this.input.PaymentInfo.Iban) {
					validationErrors.push('Het IBAN rekeningnummer moet ingevuld zijn.');
				}
				// Demo mode: validating an actual IBAN is a little annoying in demo mode. We should have a magic value to skip
				// complex validations over real financial data.
				else if (this.$cookies.get("activation-code") === "demo" && this.input.PaymentInfo.Iban.toLowerCase() === "demo") {
					// No error.
				}
				else if (!IBAN.isValid(this.input.PaymentInfo.Iban)) {
					validationErrors.push(`Het IBAN rekeningnummer is niet geldig. Controleer op typefouten of neem contact op met ${this.company.Phonenumber}.`);
				}


			}

			

			if (!this.input.AcceptedPolice) {
				validationErrors.push(`Machtig ${this.companyName} tot het doen van aangifte bij de politie.`);
			}

			if (!this.input.AcceptedTermsAndConditions) {
				validationErrors.push(`Accepteer de algemene voorwaarden om een abonnement af te kunnen sluiten.`);
			}

			if (this.settings.ServiceCosts > 0 && !this.input.AcceptedServiceCost) {
				validationErrors.push(`Accepteer de eenmalige activatie- en certificaatkosten om een abonnement af te kunnen sluiten.`);
			}

			if (!validationErrors.length) {
				// Demo mode: don't save, just go to the next page.
				if (this.$cookies.get("activation-code") === "demo"){
					this.$router.push("/check");
					return;
				}

				this.$api.post("Payment/Save", this.input).then(() => {
					this.$router.push("/check");
				});
				return;
			}

			this.$store.commit("setValidationErrors", validationErrors);
			e.preventDefault();
		}
	},

	watch: {
		
		"input.SubscriptionInfo.Name":function () {
			if(this.input.SubscriptionInfo.Name === "VilocNewSCM" ){
                this.settings.ServiceCosts = "0";
			}
		}
		

	},
	computed: mapState(["error", "company"]),
};
</script>

<style scoped lang="scss">
#payment-inputs {
	padding: 25px 60px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
	box-sizing: border-box;
	flex-grow: 1;
}

#payment-checking {
	padding: 0 60px;
	background-color: $primary;
	width: 300px;
	min-width: 300px;
	color: $white;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@media only screen and (max-width: 1199px) {
	#content-main {
		height: calc(100vh - 77px);
	}
}

@media only screen and (max-width: 1000px) {
	#payment-checking {
		display: none;
	}
}

@media only screen and (max-width: 823px) {
	#payment-inputs {
		padding: 25px;
	}
}

h3 {
	margin-bottom: 10px;
}

.h3-divider {
	margin-top: 10px;
}

a {
	color: $secondary;
	text-decoration: none;
}


.custom-select:after {
  content: "";
  font-size: 17px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 11px;
  /*Adjust for position however you want*/

  top: 18px;
  padding: 0 0 2px;
  border-bottom: 1px solid $primary;
  /*left line */

  position: absolute;
  pointer-events: none;
}

.custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  height: 37px;
  padding: 0px 5px;
  color: $primary;
  background-color: $white;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
  border: none;
  border-bottom: 1px solid $primary;
  width: 100%;
}

.custom-select select:focus {
  outline: none;
}

.custom-select select:required:invalid {
  color: $dark-gray;
}

#payment-checking > table {
	width: 100%;
	font-weight: 300;
	border-bottom: 1px solid $secondary-light;
}

#payment-checking td {
	padding: 5px 0;
}

.red-button {
	background-color: $secondary;
    color: $secondary-button;
	border: none;
}

.red-button:hover {
	background-color: $secondary-light;
}

#payment-checking > h3 {
	font-weight: 300;
	margin-bottom: 20px;
}

#payment-footer {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
}

#payment-footer > * {
	align-self: flex-end;
}

#form-grid {
	display: grid;
	grid-template-columns: 2;
	grid-column-gap: 20px;
}

.input-grid-full {
	grid-column: span 2;
}

.input-grid-half {
	grid-column: span 1;
}

.input-disabled {
	border: none;
}
</style>
