<template>
	<div>
		<div id="Vehicle-Content">
			<h3 class="h3-divider">Gefeliciteerd met de aanschaf van uw nieuwe {{data.Object.Brand}}.</h3>
			Controleer alstublieft onderstaande gegevens of neem contact met ons via: {{company.Phonenumber}}.
			<div id="Vehicle-Information">
				<template v-if="data">
					<table>
						<tr>
							<th>Activatiecode</th>
							<td>{{data.ActivationCode}}</td>
						</tr>
						<tr>
							<th>Merk</th>
							<td>{{data.Object.Brand}}</td>
						</tr>
						<tr>
							<th>Type</th>
							<td>{{data.Object.Model}}</td>
						</tr>
						<tr>
							<th>Meldcode</th>
							<td>{{checkSerialnumber()}}</td>
						</tr>
						<tr>
							<th>Kenteken</th>
							<td>{{data.Object.LicensePlate}}</td>
						</tr>
					</table>
				</template>
			</div>
      <div class="light-inputs">
		<h3 class="h3-divider">Voer uw e-mail adres in </h3>
		<p>Ter verificatie van uw e-mail adres ontvangt u een code om de activatie te starten.</p>
			
        <input
            placeholder="Email"
            class="input-full-grid"
            required="required"
            v-model="email.email"
            v-if="data && !email.mailSent"
        >

        <div class="error" v-if="email.emailValid === false">Kies een geldig emailadres a.u.b.</div>
        <input
            placeholder="Verificatie code"
            class="input-full-grid"
            required="required"
            v-model="email.verificationCode"
            v-if="email.mailSent && !email.verificationCodeValid"
        >
        <div class="error" v-if="email.verificationCodeValid === false">Verificatiecode niet correct!</div>

      </div>
			<template v-if="!data">
				Er is iets fout gegaan bij het ophalen van de voertuig gegevens.
			</template>

			<div id="vehicle-footer">
				<button @click="back">Terug</button>
        <button v-if="data && !email.mailSent" @click="sendVerificationMail" class="next-button">Verstuur verificatie mail</button>
        <button v-if="data && email.mailSent && !email.verificationCodeValid" @click="resentVerificationCode" class="next-button">Opnieuw aanvragen</button>
        <button v-if="data && email.mailSent && !email.verificationCodeValid" @click="checkVerificationCode" class="next-button">Controleer verificatiecode</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

export default {
	data() {
		return {
			data: {
				ActivationCode: null,
				Object: {
					Brand: null,
					Model: null,
					SerialNumber: null,
					LicensePlate: null
				}
			},
      email: {
        email: null,
        emailValid: null,
        mailSent: false,
        verificationCode: null,
        verificationCodeValid: null
      }
		};
	},
	created() {
		this.$emit("changeStep", 0);

		// Demo mode: don't load data that doesn't exist because we don't have an actual activation.
		if (this.$cookies.get("activation-code") === "demo"){
			return;
		}

		this.$api.get(`Object`).then((response) => {
			this.data = response.data;
		});
	},
	methods: {
		back() {
			this.$cookies.remove("activation-code");
			this.$router.push("/code");
		},
		next() {
			this.$router.push("/Login");
		},
    sendVerificationMail() {
      this.email.emailValid = this.validateEmail(this.email.email);
      if(this.email.emailValid)
      {
        this.email.mailSent = true;
        this.$api.post(`sendemailverification`, {'Email': this.email.email});
      }
    },
    validateEmail(email) {
      /*eslint-disable */
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
      /*eslint-enable */
    },
    checkVerificationCode() {
      this.$api.post(`checkemailverification`, {'VerificationCode': this.email.verificationCode}).then((response) => {
            this.email.verificationCodeValid = response.data;
            if(this.email.verificationCodeValid)
              this.$router.push("/Login");
          }
      );
    },
    resentVerificationCode() {
      this.email.verificationCodeValid = null;
      this.email.mailSent = false;
      this.email.verificationCode = null;
    },
		checkSerialnumber(){
			if (this.data.Object.SerialNumber != null) {
				return this.data.Object.SerialNumber.slice(-4);
			}
			return "Onbekend";
		},
	},
	computed: mapState(["error", "company"]),
};
</script>

<style scoped lang="scss">
#Vehicle-Content {
	padding: 25px 60px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
	box-sizing: border-box;
	flex-grow: 1;
}

#Vehicle-Information {
    margin-top: 20px;
	background-color: $white;
	width: 300px;
	min-width: 300px;
	color: $primary;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@media only screen and (max-width: 1199px) {
	#content-main {
		height: calc(100vh - 77px);
	}
}

@media only screen and (max-width: 1000px) {
	#vehicle-Information {
		display: none;
	}
}

@media only screen and (max-width: 823px) {
	#Vehicle-Content {
		padding: 25px;
	}
}

h3 {
	margin-bottom: 10px;
}

.h3-divider {
	margin-top: 10px;
}

a {
	color: $secondary;
	text-decoration: none;
}

#Vehicle-Information > table {
	width: 100%;
	font-weight: 300;
}

#Vehicle-Information td {
	padding: 5px 0;

}
#Vehicle-Information th {
    color: $primary;
	text-align: left;

}

.next-button {
	background-color: $secondary;
	color: $secondary-button;
	border: none;
}

.next-button:hover {
	background-color: $secondary-light;
}

#vehicle-footer {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
}

#vehicle-footer > * {
	align-self: flex-end;
}

#form-grid {
	display: grid;
	grid-template-columns: 2;
	grid-column-gap: 20px;
}
.light-inputs {
	margin-top: 50px;
	display:flex;
	flex-direction: column;
}
.input-full-grid{
	width: 25%;
	display:flex;
	flex-direction: column;
	color: $primary;
	margin-top:15px;
}
</style>
